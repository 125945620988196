import React, { useState } from 'react'
import { Modal, Button, List, Input, Tooltip } from 'antd'
import { Comment as AntComment } from '@ant-design/compatible'
import { LikeOutlined, LikeFilled, CommentOutlined } from '@ant-design/icons'
import { db } from '../../firebaseConfig'
import { collection, addDoc, getDocs, doc, updateDoc } from 'firebase/firestore'
import moment from 'moment'
import './MessageGrid.css'
import { Message } from '../../interfaces/Message'

const { TextArea } = Input

interface MessageGridProps {
  messages: Message[]
}

const MessageGrid: React.FC<MessageGridProps> = ({ messages }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedMessage, setSelectedMessage] = useState<Message | null>(null)
  const [newComment, setNewComment] = useState('')
  const [newReply, setNewReply] = useState('')
  const [replyToCommentId, setReplyToCommentId] = useState<string | null>(null)
  const [likeToggled, setLikeToggled] = useState<{ [key: string]: boolean }>({})

  const showModal = (message: Message) => {
    setSelectedMessage(message)
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setSelectedMessage(null)
    setNewComment('')
    setNewReply('')
    setReplyToCommentId(null)
  }

  const handleAddComment = async () => {
    if (selectedMessage) {
      const commentsCollection = collection(db, `mrKitalaMessages/${selectedMessage.id}/comments`)
      await addDoc(commentsCollection, {
        text: newComment,
        timestamp: new Date(),
        replies: []
      })
      setNewComment('')
      const updatedComments = await getDocs(commentsCollection)
      const commentsList = updatedComments.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      setSelectedMessage({ ...selectedMessage, comments: commentsList } as Message)
    }
  }

  const handleAddReply = async (commentId: string) => {
    if (selectedMessage && replyToCommentId) {
      const commentDoc = doc(db, `mrKitalaMessages/${selectedMessage.id}/comments`, replyToCommentId)
      await addDoc(collection(commentDoc, 'replies'), {
        text: newReply,
        timestamp: new Date(),
      })
      setNewReply('')
      const updatedReplies = await getDocs(collection(commentDoc, 'replies'))
      const replies = updatedReplies.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      setSelectedMessage({
        ...selectedMessage,
        comments: selectedMessage.comments.map(comment =>
          comment.id === commentId ? { ...comment, replies } : comment
        )
      } as Message)
      setReplyToCommentId(null)
    }
  }

  const handleLike = async (message: Message) => {
    if (!likeToggled[message.id]) {
      const messageDoc = doc(db, 'mrKitalaMessages', message.id)
      await updateDoc(messageDoc, {
        likes: message.likes + 1
      })
      setLikeToggled({ ...likeToggled, [message.id]: true })
      const updatedMessages = messages.map(msg =>
        msg.id === message.id ? { ...msg, likes: msg.likes + 1 } : msg
      )
      setSelectedMessage(updatedMessages.find(msg => msg.id === message.id) as Message)
    }
  }

  const getRandomColor = (id: string) => {
    const colors = ['#c99377', '#D6AE99', '#c99377', '#404245', '#B97550']
    let hash = 0
    for (let i = 0; i < id.length; i++) {
      hash = id.charCodeAt(i) + ((hash << 5) - hash)
    }
    return colors[Math.abs(hash) % colors.length]
  }

  return (
    <div className="message-grid">
      {messages.map(message => (
        <button
          key={message.id}
          className="message-card"
          style={{ backgroundColor: getRandomColor(message.id), borderColor: getRandomColor(message.id), gridRow: `span ${Math.ceil(message.text.length / 100)}` }}
          onClick={() => showModal(message)}
        >
          <div className='message-content'>
            <h3>{message.heading}</h3>
            <p>{message.text}</p>
          </div>
          <div className="message-footer">
            <Tooltip title="Like">
              <div className="icon-circle">
                <Button
                  type="text"
                  icon={message.likes > 0 ? <LikeFilled /> : <LikeOutlined />}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleLike(message)
                  }}
                >
                  {message.likes}
                </Button>
              </div>
            </Tooltip>
            <Tooltip title="Comment">
              <div className="icon-circle">
                <Button
                  type="text"
                  icon={<CommentOutlined />}
                  onClick={(e) => {
                    e.stopPropagation()
                    showModal(message)
                  }}
                >
                  {message.comments.length}
                </Button>
              </div>
            </Tooltip>
          </div>
        </button>
      ))}
      <Modal title="Message" visible={isModalVisible} onCancel={handleCancel} footer={null}>
        {selectedMessage && (
          <div className="modal-content">
            <h3>{selectedMessage.heading}</h3>
            <p className="main-message">{selectedMessage.text}</p>
            <p className="main-message">{selectedMessage.signOff}</p>
            <p className="main-message">{selectedMessage.name}</p>
            <List
              className="comment-list"
              header={`${selectedMessage.comments.length} ${selectedMessage.comments.length > 1 ? 'comments' : 'comment'}`}
              itemLayout="horizontal"
              dataSource={selectedMessage.comments}
              renderItem={comment => (
                <li key={comment.id}>
                  <AntComment
                    content={
                      <>
                        <p>{comment.text}</p>
                        <Button
                          type="link"
                          onClick={() => setReplyToCommentId(comment.id)}
                        >
                          Reply
                        </Button>
                        <div className="reply-list">
                          {comment.replies.map(reply => (
                            <AntComment
                              key={reply.id}
                              content={reply.text}
                              datetime={moment(reply.timestamp).fromNow()}
                              className="reply-comment"
                            />
                          ))}
                        </div>
                      </>
                    }
                    datetime={moment(comment.timestamp).fromNow()}
                  />
                  {replyToCommentId === comment.id && (
                    <div className="reply-form">
                      <TextArea
                        rows={2}
                        value={newReply}
                        onChange={(e) => setNewReply(e.target.value)}
                        placeholder="Add a reply..."
                      />
                      <Button
                        type="primary"
                        onClick={() => handleAddReply(comment.id)}
                        disabled={!newReply.trim()}
                      >
                        Add Reply
                      </Button>
                    </div>
                  )}
                </li>
              )}
            />
            <div className="comment-form">
              <TextArea
                rows={2}
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="Add a comment..."
              />
              <Button
                type="primary"
                onClick={handleAddComment}
                disabled={!newComment.trim()}
              >
                Add Comment
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default MessageGrid
