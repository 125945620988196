import React from 'react'
import './Contributions.css'

const Contributions: React.FC = () => (
    <div className="contributions">
      <h1>Contribute to the Family</h1>
      <section className="contribution-details">
        <div className="contribution-item">
          <h2>MPesa Paybill</h2>
          <p>Paybill No: 5010100</p>
          <p>Account: “Thomas Mwawasi Contributions”</p>
          <p>Account: your name/ group name</p>
        </div>
        <div className="contribution-item">
          <h2>MPesa</h2>
          <p>Phone: +254746104372</p>
          <p>Name: “Nekoye Otsyula”</p>
          <p>(Sister to Mrs. Lily Kitala)</p>
        </div>
        <div className="contribution-item">
          <h2>PayPal</h2>
          <p>Email: <a href="mailto:nevillekitala@gmail.com">nevillekitala@gmail.com</a></p>
        </div>
        <div className="contribution-item">
          <h2>Bank Account</h2>
          <p>Bank: [Bank Name]</p>
          <p>Account Number: [Account Number]</p>
          <p>Account Name: [Account Name]</p>
        </div>
      </section>
    </div>
  )

export default Contributions
