import React, { useEffect, useState } from 'react'
import { collection, getDocs, updateDoc, doc, addDoc } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { db, storage } from '../../src/firebaseConfig'
import { Button, Modal, Upload, Progress, message, Input, Tooltip, List } from 'antd'
import { PlusOutlined, LikeOutlined, LikeFilled, CommentOutlined } from '@ant-design/icons'
import { Comment as AntComment } from '@ant-design/compatible'
import moment from 'moment'
import type { UploadRequestOption } from 'rc-upload/lib/interface'
import './Gallery.css'

const { TextArea } = Input

interface Image {
  id: string
  src: string
  alt: string
  likes: number
  comments: Array<{ id: string, text: string, timestamp: Date }>
}

const Gallery: React.FC = () => {
  const [images, setImages] = useState<Image[]>([])
  const [modalVisible, setModalVisible] = useState(false)
  const [commentVisible, setCommentVisible] = useState<string | null>(null)
  const [newComment, setNewComment] = useState('')
  const [uploading, setUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  const fetchImages = async () => {
    const galleryCollection = collection(db, 'gallery')
    const gallerySnapshot = await getDocs(galleryCollection)
    const galleryList = await Promise.all(gallerySnapshot.docs.map(async (doc) => {
      const commentsSnapshot = await getDocs(collection(db, `gallery/${doc.id}/comments`))
      const comments = commentsSnapshot.docs.map(commentDoc => ({
        id: commentDoc.id,
        ...commentDoc.data(),
        timestamp: commentDoc.data().timestamp.toDate()
      }))
      return {
        id: doc.id,
        ...doc.data(),
        comments,
      }
    })) as Image[]
    setImages(galleryList)
  }

  useEffect(() => {
    fetchImages()
  }, [])

  const handleUpload = async (options: UploadRequestOption) => {
    const { file, onSuccess, onError } = options
    if (file instanceof File) {
      setUploading(true)
      const storageRef = ref(storage, `gallery/${file.name}`)
      const uploadTask = uploadBytesResumable(storageRef, file)

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          setUploadProgress(progress)
        },
        () => {
          setUploading(false)
          onError && onError(new Error('Upload failed.'))
          message.error('Upload failed.')
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
            await addDoc(collection(db, 'gallery'), {
              src: downloadURL,
              alt: file.name,
              likes: 0,
            })
            setUploading(false)
            setUploadProgress(0)
            onSuccess && onSuccess('Upload successful')
            message.success('Upload successful.')
            setModalVisible(false)
            fetchImages() // Fetch updated images
          } catch (error) {
            setUploading(false)
            onError && onError(new Error('Upload failed.'))
            message.error('Upload failed.')
          }
        }
      )
    } else {
      message.error('File type not supported.')
    }
  }

  const handleLike = async (image: Image) => {
    const imageDoc = doc(db, 'gallery', image.id)
    await updateDoc(imageDoc, {
      likes: image.likes + 1,
    })
    fetchImages()
  }

  const handleAddComment = async (image: Image) => {
    const commentsCollection = collection(db, `gallery/${image.id}/comments`)
    await addDoc(commentsCollection, {
      text: newComment,
      timestamp: new Date(),
    })
    setNewComment('')
    setCommentVisible(null)
    fetchImages()
  }

  const mostEngagedImage = images.reduce((max, image) => (image.likes + image.comments.length > max.likes + max.comments.length ? image : max), images[0] || { likes: 0, comments: [] })

  return (
    <div className="gallery-container">
      {uploading && <Progress percent={uploadProgress} showInfo={false} status="active" />}
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setModalVisible(true)}
        className="upload-button"
      >
        Upload Images
      </Button>
      <Modal
        title="Upload Images"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <Upload
          customRequest={handleUpload}
          listType="picture-card"
          multiple
          showUploadList={false}
        >
          <Button
            icon={<PlusOutlined />}
            onClick={(e) => e.preventDefault()} // Prevents navigation to home page
          >
            Select Files
          </Button>
        </Upload>
      </Modal>
      <div className="gallery">
        {images.map((image) => (
          <div key={image.id} className={`gallery-item ${image.id === mostEngagedImage.id ? 'most-engaged' : ''}`}>
            <img src={image.src} alt={image.alt} />
            <div className="engagement">
              <Tooltip title="Like">
                <Button
                  type="text"
                  icon={image.likes > 0 ? <LikeFilled /> : <LikeOutlined />}
                  onClick={() => handleLike(image)}
                >
                  {image.likes}
                </Button>
              </Tooltip>
              <Tooltip title="Comment">
                <Button
                  type="text"
                  icon={<CommentOutlined />}
                  onClick={() => setCommentVisible(commentVisible === image.id ? null : image.id)}
                >
                  {image.comments.length}
                </Button>
              </Tooltip>
            </div>
            {commentVisible === image.id && (
              <div className="comments">
                <List
                  className="comment-list"
                  header={`${image.comments.length} ${image.comments.length > 1 ? 'replies' : 'reply'}`}
                  itemLayout="horizontal"
                  dataSource={image.comments}
                  renderItem={comment => (
                    <li>
                      <AntComment
                        content={comment.text}
                        datetime={moment(comment.timestamp).fromNow()}
                      />
                    </li>
                  )}
                />
                <TextArea
                  rows={2}
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  placeholder="Add a comment..."
                />
                <Button
                  type="primary"
                  onClick={() => handleAddComment(image)}
                  disabled={!newComment.trim()}
                >
                  Add Comment
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Gallery
