import React, { useState } from 'react'
import { collection, addDoc } from 'firebase/firestore'
import { db } from '../../firebaseConfig'
import './MessageForm.css'

interface MessageFormProps {
  placeholder: string
  validationPrefix: string
  collectionName: string
  onSuccess?: () => void
}

const MessageForm: React.FC<MessageFormProps> = ({
  placeholder,
  validationPrefix,
  collectionName,
  onSuccess
}) => {
  const [heading, setHeading] = useState('')
  const [message, setMessage] = useState('')
  const [signOff, setSignOff] = useState('With love')
  const [name, setName] = useState('')
  const [error, setError] = useState('')

  const signOffOptions = [
    'With love',
    'Yours truly',
    'Best regards',
    'Heartfelt condolences',
    'Sincerely'
  ]

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!message.startsWith(validationPrefix)) {
      setError(`Message must start with "${validationPrefix}"`)
      return
    }

    try {
      await addDoc(collection(db, collectionName), {
        heading,
        text: message,
        signOff,
        name,
        timestamp: new Date(),
        likes: 0,
        comments: []
      })
      setHeading('')
      setMessage('')
      setSignOff(signOffOptions[0])
      setName('')
      setError('')
      if (onSuccess) onSuccess()
    } catch (err) {
      console.error('Error adding message: ', err)
      setError('Error saving message')
    }
  }

  return (
    <form onSubmit={handleSubmit} className="message-form">
      <input
        type="text"
        placeholder="Heading"
        value={heading}
        onChange={(e) => setHeading(e.target.value)}
        className="input-heading"
      />
      <textarea
        placeholder={placeholder}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        rows={4}
      />
      <select
        value={signOff}
        onChange={(e) => setSignOff(e.target.value)}
        className="input-signoff"
      >
        {signOffOptions.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <input
        type="text"
        placeholder="Your Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="input-name"
      />
      <button type="submit">Submit</button>
      {error && <p className="error">{error}</p>}
    </form>
  )
}

export default MessageForm
