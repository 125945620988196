import React from 'react'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { sendToVercelAnalytics } from './vitals'

import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'

const domNode = document.getElementById('root')
if (domNode) {
  const root = createRoot(domNode)
  root.render(
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>,
  )
} else {
  console.error('Root element not found')
}

reportWebVitals(sendToVercelAnalytics)
