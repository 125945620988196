// src/firebaseConfig.js
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyCXNq8Vdbx3ufVPv5SJZ7gOwXnz-xfXjtY',
  authDomain: 'dad-sendoff.firebaseapp.com',
  projectId: 'dad-sendoff',
  storageBucket: 'dad-sendoff.appspot.com',
  messagingSenderId: '968088694843',
  appId: '1:968088694843:web:92ea256df3a19b3189ec1e',
  measurementId: 'G-8WS3GCTC3G',
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const storage = getStorage(app)

export { db, storage }
