/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal } from 'antd'
import MessageForm from '../../components/MessageForm/MessageForm'
import moment from 'moment'
import './Home.css'
import { Event } from '../../interfaces/Event'
import MessageGrid from '../../components/MessageGrid/MessageGrid'
import { db } from '../../firebaseConfig'
import { collection, getDocs } from 'firebase/firestore'
import { Message } from '../../interfaces/Message'

const eventsData = [
  {
    title: 'Prayers From the All Saints Clergy',
    date: 'Monday 20 May - 5:00 pm',
    location: 'Kitala residence, Kitengela',
    details: 'Directions to the home will be provided.',
  },
  {
    title: 'Prayers and Fundraising',
    date: 'Tuesday 21 May - 5:00 pm',
    location: 'All Saints Cathedral,\n Kenyatta Avenue',
  },
  {
    title: 'Prayers at the Kitala residence',
    date: 'Wednesday/ Thursday 22/23 May - 4:00 pm',
    location: 'Kitala residence\n Kitengela',
    details: 'Directions to the home will be provided.',
  },
  {
    title: 'Church Funeral Service',
    date: 'Friday 24 May - 12:00 pm',
    location: 'All Saints Cathedral\n Kenyatta Avenue',
  },
  {
    title: 'Overnight Vigil',
    date: 'Friday 24 May - 8:00 pm',
    location: 'Kitala residence, Kitengela',
    details: 'Directions to the home will be provided.',
  },
  {
    title: 'Burial Service',
    date: 'Saturday 25 May - 9:00 am',
    location: 'Imaroro, Off Mashuru Kajiado Road',
    details: 'Directions to the home will be provided.',
  },
]

const getNextEvent = (events: Event[]) => {
  const now = moment()
  return events.find(event => moment(event.date, 'dddd DD MMM - h:mm a').isAfter(now))
}

const Home: React.FC = () => {
  const [nextEvent, setNextEvent] = useState<Event | undefined>(getNextEvent(eventsData))
  const [timeToNextEvent, setTimeToNextEvent] = useState<string | null>(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [messages, setMessages] = useState<Message[]>([])

  useEffect(() => {
    const fetchMessages = async () => {
      const messagesCollection = collection(db, 'mrKitalaMessages')
      const messagesSnapshot = await getDocs(messagesCollection)
      const messagesList = await Promise.all(
        messagesSnapshot.docs.map(async doc => {
          const commentsSnapshot = await getDocs(collection(db, `mrKitalaMessages/${doc.id}/comments`))
          const comments = await Promise.all(
            commentsSnapshot.docs.map(async commentDoc => {
              const repliesSnapshot = await getDocs(collection(db, `mrKitalaMessages/${doc.id}/comments/${commentDoc.id}/replies`))
              const replies = repliesSnapshot.docs.map(replyDoc => ({
                id: replyDoc.id,
                text: replyDoc.data().text,
                timestamp: replyDoc.data().timestamp.toDate()
              }))
              return {
                id: commentDoc.id,
                text: commentDoc.data().text,
                timestamp: commentDoc.data().timestamp.toDate(),
                replies
              }
            })
          )
          return {
            id: doc.id,
            heading: doc.data().heading,
            text: doc.data().text,
            signOff: doc.data().signOff,
            name: doc.data().name,
            timestamp: doc.data().timestamp.toDate(),
            likes: doc.data().likes,
            comments
          }
        })
      )
      setMessages(messagesList)
    }
    fetchMessages()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment()
      const upcomingEvent = getNextEvent(eventsData)
      setNextEvent(upcomingEvent)

      if (upcomingEvent) {
        const duration = moment.duration(moment(upcomingEvent.date, 'dddd DD MMM - h:mm a').diff(now))
        setTimeToNextEvent(`${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`)
      } else {
        setTimeToNextEvent(null)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <main className="home">
      <section className="cover-section">
        <div className="cover-content">
          <img
            src="/images/coverText.png"
            alt="Thomas M. Kitala"
            className="cover-text"
          />
          <br />
          <br />
          <div className="action-buttons">
            <Link to="/about" className="button btn-primary">
              About
            </Link>
            <Link to="/gallery" className="button btn-primary">
              Gallery
            </Link>
            <Link to="/contributions" className="button btn-secondary">
              Contributions
            </Link>
          </div>
        </div>
      </section>

      {nextEvent && (
        <section className="next-event">
          <h3 className="countdown">Starts in: {timeToNextEvent}</h3>
          <div className="next-event-details">
            <div className="event-date">
              <h2>{moment(nextEvent.date, 'dddd DD MMM - h:mm a').format('DD')}</h2>
              <h3>{moment(nextEvent.date, 'dddd DD MMM - h:mm a').format('MMM')}</h3>
            </div>
            <div className="event-details">
              <h2>{nextEvent.title}</h2>
              <p>{nextEvent.location}</p>
              {nextEvent.details && <p>{nextEvent.details}</p>}
            </div>
          </div>
        </section>
      )}

      <section className="message-section">
        <div className='tribute-button'>
          <h1> Tributes to Mr. Kitala</h1>
          <Button type="primary" onClick={showModal}>
            Create a Tribute +
          </Button>
        </div>
        <MessageGrid messages={messages} />
        <Modal title="Leave a Message" visible={isModalVisible} onCancel={handleCancel} footer={null}>
          <MessageForm
            placeholder="Mr Kitala was..."
            validationPrefix=""
            collectionName="mrKitalaMessages"
            onSuccess={() => setIsModalVisible(false)}
          />
        </Modal>
      </section>
    </main>
  )
}

export default Home
