import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Header from './components/Header/Header'
import Home from './pages/Home/Home'
import About from './pages/About/About'
import Gallery from './pages/Gallery'
import Contributions from './pages/Contributions/Contributions'
import Events from './pages/Events/Events'

const App: React.FC = () => (
  <>
    <Header />
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/about" component={About} />
      <Route path="/events" component={Events} />
      <Route path="/gallery" component={Gallery} />
      <Route path="/contributions" component={Contributions} />
    </Switch>
  </>
)

export default App
