import React from 'react';
import './About.css';

const About: React.FC = () => (
    <div className="about-container">
      <h1>Thomas Mwawasi Kitala</h1>

      <section>
        <h2>Early Years</h2>
        <p>
          Thomas was born on 9th December 1969 in Kajiado and was the son of the late Neville Paul Kitala Mwawasi and the late Christina Mkamwasi Mwakala. He shared a home with his seven siblings: Josephine Kitala, Elizabeth Wachia, Dorah Mkabili, Charles Mwakala, Dorothy Olang and William Kongoda. The Kitala family resided in railway quarters all over the country as their father worked for Kenya railways.
        </p>
      </section>

      <section>
        <h2>Education</h2>
        <p>
          Thomas began his early education at X primary school. He then studied at Upper Hill Boys in his first and second years and completed his O-levels at Voi secondary school. Thomas was a multifaceted young man who made his mark inside and outside the classroom. He was a top student who recognized the opportunities education could bring in life. Outside the classroom, he was a sportsman who loved playing rugby on the school team with his friends. Thomas also had an artistic and passionate spirit; he enjoyed lending his vibrant voice to the school musical choir and drama club. At Kabarak High School he completed his A – levels and was the entertainment captain.
        </p>
        <p>
          He completed his NYS year. He pursued a Bachelor of Commerce (Accounting) at Kenyatta University, graduating in 1993 alongside his wife, Lily Nanga.
        </p>
      </section>

      <section>
        <h2>Marriage and Family</h2>
        <p>
          He met the love of his life Lily Nanga Otsyula at Kenyatta University in 1989 and married her on August 9th, 1997. They would have celebrated 27 years of marriage this year. Thomas was a loving and devoted husband who deeply loved and cherished his family. He was the light of their lives and added warmth, vibrancy, and colour to the home. He will be missed dearly.
        </p>
        <p>
          Together they have three beautiful children Neville, Divine and Barry. Thomas was a doting father, who deeply loved and cherished his family. He was the light of their lives and added warmth, vibrancy and colour to the home. He will be missed dearly.
        </p>
      </section>

      <section>
        <h2>Career</h2>
        <p>
          Mr Kitala&#39;s working career spans thirty years with his earliest achievements as an export manager at Ocean Freight Shipping Company in 1994 before moving to Caltex as an Export Sales Executive in the same year. From 2003-2006 he served as the regional marketing manager at Hass Petroleum. Before his untimely demise he was the East African Manager at Interpetrol Group.
        </p>
      </section>

      <section>
        <h2>Stepping Stones Chapter</h2>
        <p>
          Tom together with Lily founded Stepping Stones in 2008/2009 where he served as the director of the school until the point of his untimely death. He was beloved by all of his students and members of staff.
        </p>
      </section>

      <section>
        <h2>Death and Funeral Announcement</h2>
        <p>
          It is with deep sorrow and humble acceptance of God&#39;s will that we announce the untimely demise of Thomas Morgan Mwawasi Kitala (TJ) on Wednesday, 15th May 2024. He was the beloved son of the late Neville Paul Kitala and the late Christina Mkamwasi Kitala. Loving husband of Lily Nanga Otsyula Kitala and devoted father to Neville Kitala and Audrey Twesigye, Divine Mkamwasi, Barry Otsyula, and Jamal Tarik. Son-in-law of Professor/Dr B.O Khwa Otsyula and Dr/Mrs B.O Khwa Otsyula, and the Namisi/Sifuma family. Brother of Josephine Kitala Adidi, the late Elizabeth Wachia Kitala, Dorah Mkabili Kitala, Charles Mwakala Kitala, Dorothy Olang, and William Kongoda Kitala. Brother-in-law of the Otsyulas, including Nick and Esther, Ernest and Veronica, Dr Nekoye and Gonzaga, Majiba, Dr Makokha, Ruth Njenga, Ikuni, Nanzala, Pauline, Dr Robai and Pauline Nanga among others. Doting uncle of Violet and Wali, Lizzie and Jabez, Nelly Kavere, Kristina and Ken, Ashley and Tina Mganga, Edel Adidi, Christina and Mike, Newton, Daniella and Dilan, Neville Junior and Michael, Branley, Cayden and Trey. Nephew of the Mwakala Msengo family and the larger Kitala and Mwailengo family. Cherished friend to many.
        </p>
      </section>
    </div>
  );

export default About;
