// src/components/Events/Events.tsx
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import './Events.css'
import { Event } from '../../interfaces/Event'

const eventsData: Event[] = [
  {
    title: 'Prayers From the All Saints Clergy',
    date: 'Monday 20 May - 5:00 pm',
    location: 'Kitala residence, Kitengela',
    details: 'Directions to the home will be provided.',
  },
  {
    title: 'Prayers and Fundraising',
    date: 'Tuesday 21 May - 5:00 pm',
    location: 'All Saints Cathedral, Kenyatta avenue',
  },
  {
    title: 'Prayers at the Kitala residence',
    date: 'Wednesday/ Thursday 22/23 May - 4:00 pm',
    location: 'Kitala residence, Kitengela',
    details: 'Directions to the home will be provided.',
  },
  {
    title: 'Church Funeral Service',
    date: 'Friday 24 May - 12:00 pm',
    location: 'All Saints Cathedral, Kenyatta avenue',
  },
  {
    title: 'Overnight Vigil',
    date: 'Friday 24 May - 8:00 pm',
    location: 'Kitala residence, Kitengela',
    details: 'Directions to the home will be provided.',
  },
  {
    title: 'Burial Service',
    date: 'Saturday 25 May - 9:00 am',
    location: 'Imaroro, Off Mashuru Kajiado Road',
    details: 'Directions to the home will be provided.',
  },
]

const getNextEvent = (events: Event[]) => {
  const now = moment()
  return events.find(event => moment(event.date, 'dddd DD MMM - h:mm a').isAfter(now))
}

const Events: React.FC = () => {
  const [events, setEvents] = useState(eventsData)
  const [nextEvent, setNextEvent] = useState<Event | undefined>(getNextEvent(eventsData))
  const [timeToNextEvent, setTimeToNextEvent] = useState<string | null>(null)
  const [allEventsFinished, setAllEventsFinished] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment()
      const updatedEvents = events.map(event => ({
        ...event,
        finished: moment(event.date, 'dddd DD MMM - h:mm a').isBefore(now),
      }))
      setEvents(updatedEvents)

      const upcomingEvent = getNextEvent(updatedEvents)
      setNextEvent(upcomingEvent)

      if (upcomingEvent) {
        const duration = moment.duration(moment(upcomingEvent.date, 'dddd DD MMM - h:mm a').diff(now))
        setTimeToNextEvent(`${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`)
      } else {
        setAllEventsFinished(true)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [events])

  return (
    <div className="events">
      <h1>Upcoming Events</h1>
      {timeToNextEvent && nextEvent && (
        <div className="timer">
          <h2>Next Event: {nextEvent.title} in {timeToNextEvent}</h2>
        </div>
      )}
      <div className="events-list">
        {events.map((event, index) => (
           <div key={index} className={`event ${event.finished ? 'finished' : ''}`}>
             <div className="event-date">
               <h2>{moment(event.date, 'dddd DD MMM - h:mm a').format('DD')}</h2>
               <h3>{moment(event.date, 'dddd DD MMM - h:mm a').format('MMM')}</h3>
             </div>
             <div className="event-details">
               <h2>{event.title}</h2>
               <p>{event.location}</p>
               {event.details && <p>{event.details}</p>}
              {event.finished && <span className="tag">Finished</span>}
             </div>
           </div>
        ))}
      </div>
      {allEventsFinished && (
        <div className="scrolling-poem">
          <p>Dad has been buried and laid to rest. May his soul rest in eternal peace.</p>
        </div>
      )}
    </div>
  )
}

export default Events
