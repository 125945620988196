import React from 'react'
import Gallery from '../components/Gallery'

const GalleryPage: React.FC = () => (
  <main>
    <h1>Photo Gallery</h1>
    <Gallery />
  </main>
)

export default GalleryPage
